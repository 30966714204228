




import Vue from 'vue';
import { defineComponent, ref, Ref } from '@vue/composition-api';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import { ACL } from '../../../../modules/acl/acl-service';

const chatHelpers = createNamespacedHelpers('chat');

export default defineComponent({
  setup() {
    const { openChat }: {
      openChat: () => Promise<void>
    } = chatHelpers.useActions(['openChat']) as any;

    if (ACL.can('messenger.user-contacts')) {
      openChat();
    }

    return {};
  },
});
